<template>
  <div class="news">
    <Head :type=0></Head>
    <Carousel></Carousel>
    <div class="news_con">
      <div class="news_tab">
        <div :class="['tab_list',indexShow == 0 ? 'tab_list1' : '']" @click="choice(0)" >
          <img v-if="indexShow == 0" src="../assets/icon/xinwen-icon.png" alt="">
          <img v-else src="../assets/icon/xinwen-icon1.png" alt="">
          <span>新闻动态</span>
        </div>
        <div :class="['tab_list',indexShow == 1 ? 'tab_list1' : '']" @click="choice(1)" >
          <img v-if="indexShow == 1" src="../assets/icon/qiye-icon2.png" alt="">
          <img v-else src="../assets/icon/qiye-icon.png" alt="">
          <span>企业动态</span>
        </div>
        <div :class="['tab_list',indexShow == 2 ? 'tab_list1' : '']" @click="choice(2)" >
          <img v-if="indexShow == 2" src="../assets/icon/meitibaodao-icon1.png" alt="">
          <img v-else src="../assets/icon/meitibaodao-icon.png" alt="">
          <span>媒体报道</span>
        </div>
        <div :class="['tab_list',indexShow == 3 ? 'tab_list1' : '']" @click="choice(3)" >
          <img v-if="indexShow == 3" src="../assets/icon/xinwen-icon.png" alt="">
          <img v-else src="../assets/icon/hangyezixun-icon.png" alt="">
          <span>行业资讯</span>
        </div>
        <div :class="['tab_list',indexShow == 4 ? 'tab_list1' : '']" @click="choice(4)" >
          <img v-if="indexShow == 4" src="../assets/icon/shichanghuodong2.png" alt="">
          <img v-else src="../assets/icon/shichanghuodong.png" alt="">
          <span>市场活动</span>
        </div>
      </div>
      <div>
        <div class="news_list" v-for="(item,index) in newsList" :key="index" @click="goDetail(item)">
          <div class="list_left wow bounceInLeft">
            <img class="imgBg" :src="item.pictureAddress" alt="">
          </div>
          <div class="list_right wow bounceInRight">
            <div class="title">{{item.title}}</div>
            <p class="content">{{item.description}}</p>
            <!-- <p class="content">构建安心，安全，舒适的社会基础设施，需要先进的IT技术。万线多年来始终针对时代发 展的新需求，向用户提供可靠、高质量的IT产品及服务。构建安心，安全，舒适的社会基 展的新需求，向用户提供可靠、高质量的IT产品及服务。</p> -->
            <p class="time">{{item.createTime.substring(0,10)}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
      background
      prev-text="上一页"
      next-text="下一页"
      layout="prev, pager, next"
      :total=total
      :page-size="size"
      :current-page="current"
      @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <RightSide></RightSide>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import Carousel from "@/components/Carousel.vue";
import RightSide from "@/components/RightSide.vue";
import { getNews } from "@/api/common";
export default {
  name: "News",
  components: {
    Head,
    Footer,
    Carousel,
    RightSide
  },
  data() {
    return {
      indexShow: 0,
      current:  1,
      size: 10,
      newsType: 1,
      total: 0,
      newsList: []
    }
  },
  methods: {
    goMore() {
      this.$router.push('/homeERP')
    },
    choice(index) {
      this.indexShow = index
      this.newsType = index+1
      this.current = 1
      this.getNews()
    },
    goDetail(val) {
      this.$router.push({
        path: '/newsLaunch',
        query: {
          index: val.id
        }
      })
    },
    handleCurrentChange(val) {
      this.current = val
      this.getNews()
    },
    //新闻分页
    getNews() {
      let params = {
        current: this.current,
        size: this.size,
        newsType: this.newsType
      };
      getNews(params).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.data.total
          this.newsList = res.data.data.records
        } else {

        }
      });
    },
  },
  mounted() {
    this.getNews()
    let wow = new WOW(
      {
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: true,
        callback: function (box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        }
      }
    );
    wow.init();
  },
};
</script>
<style scoped lang="less">
.news {
  .news_con {
    width: 1280px;
    margin: 0 auto;
    box-sizing: border-box;
    .news_tab {
      width: 100%;
      margin-top: 34px;
      margin-bottom: 3px;
      display: flex;
      .tab_list {
        width: 20%;
        height: 80px;
        background: #E5E5E5;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          margin-right: 12px;
        }
      }
      .tab_list1 {
        background: #008CD7;
        color: #ffffff;
      }
    }
    .news_list {
      box-sizing: border-box;
      width: 100%;
      padding: 39px 35px;
      display: flex;
      border-bottom: 1px solid #999999;
      overflow: hidden;
      .list_left {
        flex:0 0 400px;
        height: 300px;
        overflow: hidden;
        .imgBg {
          width: 400px;
          height: 300px;
          display: block;
          cursor: pointer;
	        transition: all 0.4s
        }
        .imgBg:hover {
          transform: scale(1.2);
        }
      }
      .list_right {
        flex: 1;
        padding-left: 22px;
        box-sizing: border-box;
        .title {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1D1D1D;
          line-height: 40px;
        }
        .content {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 40px;
        }
        .time {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 40px;
          text-align: right;

        }
      }
    }
  }
  .pagination {
    width: 100%;
    text-align: center;
    margin-bottom: 88px;
    margin-top: 90px;
  }
}
</style>
<style lang="less">
.news {
  .el-pagination.is-background .btn-next{
     padding: 0px 10px;
  }
  .el-pagination.is-background .btn-prev{
     padding: 0px 10px;
  }
}
</style>
